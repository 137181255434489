define("ember-router-scroll/services/router-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RouterScroll = Ember.Service.extend({
    isFastBoot: Ember.computed(function () {
      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),
    key: null,
    scrollElement: 'window',
    targetElement: null,
    isFirstLoad: true,
    preserveScrollPosition: false,
    delayScrollTop: false,
    // ember-app-scheduler properties
    scrollWhenPainted: false,
    scrollWhenIdle: false,
    init: function init() {
      this._super.apply(this, arguments);

      this._loadConfig();

      Ember.set(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      });
    },
    unsetFirstLoad: function unsetFirstLoad() {
      Ember.set(this, 'isFirstLoad', false);
    },
    update: function update() {
      if (Ember.get(this, 'isFastBoot') || Ember.get(this, 'isFirstLoad')) {
        return;
      }

      var scrollElement = Ember.get(this, 'scrollElement');
      var targetElement = Ember.get(this, 'targetElement');
      var scrollMap = Ember.get(this, 'scrollMap');
      var key = Ember.get(this, 'key');
      var x;
      var y;

      if (targetElement) {
        var element = document.querySelector(targetElement);

        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop; // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen

          Ember.set(scrollMap, 'default', {
            x: x,
            y: y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        var _element = document.getElementById(scrollElement.substring(1));

        if (_element) {
          x = _element.scrollLeft;
          y = _element.scrollTop;
        }
      } // only a `key` present after first load


      if (key && 'number' === Ember.typeOf(x) && 'number' === Ember.typeOf(y)) {
        Ember.set(scrollMap, key, {
          x: x,
          y: y
        });
      }
    },
    _loadConfig: function _loadConfig() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.routerScroll) {
        var scrollElement = config.routerScroll.scrollElement;
        var targetElement = config.routerScroll.targetElement;
        (true && !(!(scrollElement && targetElement)) && Ember.assert('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));

        if ('string' === Ember.typeOf(scrollElement)) {
          Ember.set(this, 'scrollElement', scrollElement);
        }

        if ('string' === Ember.typeOf(targetElement)) {
          Ember.set(this, 'targetElement', targetElement);
        }

        var _config$routerScroll = config.routerScroll,
            _config$routerScroll$ = _config$routerScroll.scrollWhenPainted,
            scrollWhenPainted = _config$routerScroll$ === void 0 ? false : _config$routerScroll$,
            _config$routerScroll$2 = _config$routerScroll.scrollWhenIdle,
            scrollWhenIdle = _config$routerScroll$2 === void 0 ? false : _config$routerScroll$2,
            _config$routerScroll$3 = _config$routerScroll.delayScrollTop,
            delayScrollTop = _config$routerScroll$3 === void 0 ? false : _config$routerScroll$3;
        Ember.set(this, 'delayScrollTop', delayScrollTop);
        Ember.set(this, 'scrollWhenPainted', scrollWhenPainted);
        Ember.set(this, 'scrollWhenIdle', scrollWhenIdle);
      }
    }
  });
  Object.defineProperty(RouterScroll.prototype, 'position', {
    configurable: true,
    get: function get() {
      var scrollMap = Ember.get(this, 'scrollMap');
      var stateUuid = Ember.get(window, 'history.state.uuid');
      Ember.set(this, 'key', stateUuid);
      var key = Ember.getWithDefault(this, 'key', '-1');
      return Ember.getWithDefault(scrollMap, key, scrollMap.default);
    }
  });
  var _default = RouterScroll;
  _exports.default = _default;
});